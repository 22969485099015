import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ButtonBol from './AccordionButtons/ButtonBol.js'
import ButtonCopy from './AccordionButtons/ButtonCopy.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faIgloo, faFileAlt, faCopy, faChevronCircleDown, faBookmark } from '@fortawesome/free-solid-svg-icons'

library.add(faIgloo, faFileAlt, faCopy, faChevronCircleDown, faBookmark)


const AccordionComponentShipmentsStatic = styled.div`
  .es-c-shipment-accordion {
    .es-cg-shipment-accordion {
      // 💅
      &:hover {
        background-color: #e7edf0;
        
        cursor: pointer;
      }
      &:hover .IconBoxWatch {
        background-color: transparent;
        border-color: transparent;

        transition: 100ms ease, color 100ms ease;
        i {
          color: #0033a1;

          transition: 100ms ease, color 100ms ease;
        }
        p {
          color: #0033a1;;
          display: block;
          transition: 100ms ease, color 100ms ease;
        }
      }
      // 🎛
      font-family: "Open Sans";
      // 🎨
      border-radius: 3px;
      background-color: #f7f7f7;
      margin-bottom: 3px;
      padding-top: 4px;
      // 🖋
      color: #141b1f;
      font-size: 12px;
      font-weight: 400;
      // 📰
      display: grid;
      grid-template-rows: 48px;
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: "sg_watch_btn sg_shipment sg_status sg_origin sg_destination sg_pickup sg_delivery sg_carrier sg_total_cost sg_bol_btn sg_copy_btn sg_carrot_btn";

      span {
        font-size: 12px;
        color: #666;
        font-weight: 400;
      }
      .sg-watch-btn {
        grid-area: sg_watch_btn; // 🗺
        align-self: center;
        justify-self: center;
      }
      .sg-shipment {
        grid-area: sg_shipment; // 🗺
        align-self: center;
        text-decoration: underline;
        color: #0033a1;
        padding-top: 15px;
      }
      .sg-status {
        grid-area: sg_status; // 🗺
        align-self: center;
      }
      .sg-origin {
        grid-area: sg_origin; // 🗺
        align-self: center;
      }
      .sg-destination {
        grid-area: sg_destination; // 🗺
        align-self: center;
      }
      .sg-pickup {
        grid-area: sg_pickup; // 🗺
        align-self: center;
        span {
          font-style: italic;
        }
      }
      .sg-delivery {
        grid-area: sg_delivery; // 🗺
        align-self: center;
        span {
          font-style: italic;
        }
      }
      .sg-carrier {
        grid-area: sg_carrier; // 🗺
        align-self: center;
      }
      .sg-total-cost {
        grid-area: sg_total_cost; // 🗺
        align-self: center;
      }
      .sg-bol-btn {
        grid-area: sg_bol_btn; // 🗺
        align-self: center;
        justify-self: center;
      }
      .sg-copy-btn {
        grid-area: sg_copy_btn; // 🗺
        align-self: center;
        justify-self: center;
      }
      .sg-carrot-btn {
        grid-area: sg_carrot_btn; // 🗺
        align-self: center;
        justify-self: center;
      }
    }
  }
`

const ButtonWatchOne = styled.button`
  /* Clear default button styles */
  background-color: transparent;
  color: transparent;
  border: transparent;
  border-radius: 3px;
  -webkit-appearance: none;
  padding: 0px;

  transition: 100ms ease, color 100ms ease; 

  .IconBoxWatch {
    width: 45px;
    padding: 6px 0px;
    border-radius: 3px;

    transition: 100ms ease, color 100ms ease; 
  }
  i {
    color: #bbb;

    transition: 100ms ease, color 100ms ease; 
  }
  p {
    display: none;
    font-size: 10px;
    vertical-align: middle;

    transition: 100ms ease, color 100ms ease; 
  }
  /* 💅 HOVER STYLES */
  :hover .IconBoxWatch {
    color: #fff;
    background-color: #26c1fb;
    border-color: #26c1fb;

    transition: 100ms ease, color 100ms ease; 

  :hover i {
    color: #26c1fb !important;

    transition: 100ms ease, color 100ms ease; 
  }
  :hover p {
    color: #26c1fb !important;
    display: block;

    transition: 100ms ease, color 100ms ease; 
  }
}
`;

const EchoShipAccordionComponent = () => {
  return (
    <AccordionComponentShipmentsStatic>
      <div className="es-c-shipment-accordion">
        <div className="accordion es-cg-shipment-accordion">
          <input
            type="checkbox"
            id="shipment-accordion-1"
            name="accordion-checkbox"
            hidden
          />
          <div className="sg-watch-btn">
            <ButtonWatchOne>
            <Link to="/Shipments/ShipmentTwo/">
              <div className="IconBoxWatch fade-in">
                <i class="far fa-bookmark" />
                <p>Watch</p>
              </div>
              </Link>
            </ButtonWatchOne>
            
          </div>
          <Link to="/Shipments/ShipmentDetails/ShipmentPickedUp/">
            <div className="sg-shipment">34954115</div>
          </Link>
          <div className="sg-status">Picked Up</div>

          <div className="sg-origin">
            Brampton, ON
            <br />
            <span>L6T 5S8</span>
          </div>
          <div className="sg-destination">
            ELK GROVE VILLAGE, IL
            <br />
            <span>60007</span>
          </div>
          <div className="sg-pickup">
            Mon Apr 1
            <br />
            <span>
              6:00 AM
              <br />
              Estimated
              <br />
            </span>
          </div>
          <div className="sg-delivery">
            Mon Apr 1
            <br />
            <span>2:00 PM</span>
            <br />
            <span>Estimated</span>
          </div>
          <div className="sg-carrier">
          Central Transport
            <br />
            LTL
          </div>
          <div className="sg-total-cost">
            $202.90
            <br />
            <span>Unbilled</span>
          </div>
          <div className="sg-bol-btn">
            <ButtonBol />
          </div>
          <div className="sg-copy-btn">
            <ButtonCopy />
          </div>

          <div
            className="accordion-header sg-carrot-btn"
            htmlFor="shipment-accordion-1"
          >
            <label className="accordion-header" htmlFor="shipment-accordion-1">
              <FontAwesomeIcon icon="chevron-circle-down" />
            </label>
          </div>

          <div className="accordion-body">
            <p>
              80311954
              <br />
              Load BOL #<br />
              10184598
              <br />
              PO #<br />
            </p>
          </div>
        </div>
      </div>
    </AccordionComponentShipmentsStatic>
  )
}

export default EchoShipAccordionComponent;